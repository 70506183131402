@use "sass:map";

@use "../../../../sass/utils/variables";
@use "../../../../sass/utils/palette";

// Default dropdown panel style to align with figma - moves panel directly under mat-select component
.cdk-overlay-container
  > .cdk-overlay-connected-position-bounding-box
  > .cdk-overlay-pane.GA_SELECT_PANEL {
  transform: none !important;

  .mdc-menu-surface.mat-mdc-select-panel {
    padding: 0;
    min-width: fit-content;
  }

  &
    > .ng-trigger-transformPanel.mat-mdc-select-panel.mat-primary.ng-trigger[role="listbox"] {
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
      .mdc-list-item__primary-text {
      font-size: 1rem;
      color: black;
    }
  }

  & .mat-mdc-option {
    padding: 1rem;
  }
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  ) {
  // Clean up padding to align with figma
  .mat-mdc-form-field-infix,
  &.mat-mdc-focused .mat-mdc-form-field-infix {
    padding-top: 1.75rem;
    padding-bottom: 0.25rem;
  }

  //  Formfield Label to align with figma styling - increases size of label and aligns label to match figma
  &.mat-mdc-form-field-label-always-float .mat-mdc-floating-label,
  &.mat-mdc-form-field-can-float
    .mat-input-server:focus
    + .mat-mdc-form-field-label-wrapper
    .mat-mdc-form-field-label {
    transform: translateY(-0.8125em) scale(1) perspective(100px)
      translateZ(0.001px);
    font-size: 1rem;
    color: palette.$dark-primary-text;
    font-weight: 300;
  }

  // Removes background color and padding to align with figma
  .mat-mdc-form-field-flex {
    padding: 0;
    display: flex;
    align-items: center;
  }

  // Form field underline styling - adjusts color to match figma
  .mat-mdc-form-field-underline::before {
    background-color: map.get(palette.$grey-palette, 300);
  }

  // Form field underline styling - adjusts positioning to match figma
  .mat-mdc-form-field-underline {
    transform: scale3d(1, 0.45, 1);
  }

  // Form field underline styling - height(underline thickness) to match figma
  .mat-mdc-form-field-ripple {
    height: 4px;
  }

  .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: black;
  }

  // Form field underline styling - ripple styling on focus
  &.mat-focused {
    .mat-mdc-form-field-ripple {
      background-color: rgba(black, 0.87);
      height: 2px;
    }

    &.mat-mdc-form-field-invalid .mat-mdc-form-field-ripple {
      height: 1px;
    }
  }

  // Adjusts underline color to match input fields
  .mat-mdc-form-field-appearance-standard .mat-mdc-form-field-underline {
    background-color: rgba(0, 0, 0, 0.35) !important;
  }

  // Mat-select styling
  mat-select.mat-mdc-select {
    line-height: normal;
    font-size: 1.5rem;

    // Allows room for custom caret and error icon
    &.mat-select-invalid .mat-mdc-select-value {
      // width: 85%;
      &:after {
        animation: select_caret_animation_go_left 0.3s linear forwards;
      }
    }

    // remove default caret and add custom caret
    .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
      width: 1rem;
      margin-left: 1rem;

      svg {
        display: none;
      }

      &:after {
        content: "";
        background: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7275 0.364258L8.86328 8.22852L0.999024 0.364258' stroke='black' stroke-width='1.1' /%3E%3C/svg%3E");
        width: 1.125rem;
        height: 0.52rem;
        display: block;
        position: relative;
        right: 0.5rem;
        bottom: calc(50% - 0.5rem);
      }
    }

    // Adjusts mat-select height
    .mat-mdc-select-trigger {
      min-height: 2.5rem;
      height: auto;
      position: relative;

      mat-select-trigger {
        font-weight: 800;
      }

      .mat-mdc-select-arrow-wrapper {
        transform: translateY(-5px);
        height: 0.5rem;
      }
    }

    // Default font weight set to bold
    & span.mat-mdc-select-min-line {
      font-weight: 800;
    }

    // Font weight optional class "mat-select--font-weight-light" to change selected option font weight to light
    &.mat-select--font-weight-light {
      span.mat-mdc-select-min-line,
      mat-select-trigger {
        font-weight: 300;
      }
    }
  }

  // hint styling - adjusts positioning for hint
  .mat-mdc-form-field-subscript-wrapper {
    margin-top: 0.5rem;
  }

  // font weight to normal for error and hint text
  mat-error.mat-mdc-error,
  mat-hint.mat-mdc-form-field-hint,
  &.mat-mdc-focused mat-error.mat-mdc-error,
  &.mat-mdc-focused mat-hint.mat-mdc-form-field-hint {
    font-weight: normal;
    font-size: 0.75rem;
  }

  // Error icon
  &.mat-form-field-invalid {
    .mat-mdc-form-field-flex {
      display: flex;
      align-items: center;

      .mat-mdc-select-arrow-wrapper {
        display: flex;
        align-items: center;
      }

      .mat-mdc-select-arrow-wrapper::before {
        content: "";
        width: 20px;
        height: 21px;
        background: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM11 15.5H9V13.5H11V15.5ZM11 11.5H9V5.5H11V11.5Z' fill='%23E12621'/%3E%3C/svg%3E");
        display: block;
        position: relative;
        margin-right: 0.25rem;
        margin-left: 0.25rem; // Add margin-left to create space between the error icon and the select arrow
        bottom: calc(50% - 0.6rem);
        border-radius: 50%;
        animation: invalid_error_icon_animation 1s linear;
      }
    }
  }
}

// Default styling when
.ga-card-header {
  mat-select.mat-mdc-select
    .mat-mdc-select-trigger
    .mat-mdc-select-arrow-wrapper:after {
    content: "";
    display: block;
    width: 13px;
    margin: 0 1rem;
    height: 8px;
    transform: translateY(0.1rem);
    background: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9889 0.656854L6.33203 6.31371L0.675177 0.656854' stroke='black'/%3E%3C/svg%3E%0A");
  }

  mat-select.mat-mdc-select
    .mat-mdc-select-trigger
    .mat-mdc-select-arrow-wrapper
    > .mat-mdc-select-arrow {
    display: none;
  }
}

// Animation for when error occurs
@keyframes select_caret_animation_go_left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-2rem);
  }
}

// Lagecy
// Default dropdown panel style to align with figma - moves panel directly under mat-select component
.cdk-overlay-pane
  > .mat-select-panel-wrap
  > .ng-trigger-transformPanel.mat-select-panel.mat-primary.ng-trigger[role="listbox"] {
  min-width: 100% !important;
  transform: scale(1) translateX(1rem) translateY(4rem) !important;

  mat-option.mat-option {
    font-size: 1rem;

    .mat-option-text {
      color: black;
    }
  }
}

.chip-trigger-text {
  min-height: 41px;
}

// TODO: Select form field -----------------------------------------------------------------------------------------------
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  )
  mat-select.mat-mdc-select
  .mat-mdc-select-trigger {
  line-height: 2.5rem;
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  )
  .mat-mdc-form-field-infix,
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  ).mat-mdc-focused
  .mat-mdc-form-field-infix {
  padding-bottom: 0.2rem;
}

// ----------------------------------------------------------------------------------------------------------------------

// wrap selected option for mat select
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select:not(
    .mat-mdc-paginator-page-size-select
  ) {
  mat-select.mat-mdc-select.mat-select--wrap-selected-option {
    .mat-mdc-select-value {
      // width: 90%;
      letter-spacing: 0.05rem;
      min-height: 31px;
      line-height: 31px;

      .mat-mdc-select-value-text {
        white-space: break-spaces;
        overflow: visible;
        text-overflow: initial;
      }
    }
  }
}

.mat-mdc-select-trigger {
  height: 100%;
}
